// extracted by mini-css-extract-plugin
export var box = "error-module--box--iDZEE";
export var boxButton = "error-module--box-button--c3gKa";
export var boxDescription = "error-module--box-description--MN7dH";
export var boxDescriptionContainer = "error-module--box-description-container--OTWwE";
export var boxDescriptionText = "error-module--box-description-text--wurTb";
export var boxDescriptionTitle = "error-module--box-description-title--xLMiR";
export var boxEyeLeft = "error-module--box-eye-left--bcr76";
export var boxEyeRight = "error-module--box-eye-right--jNbet";
export var boxGhost = "error-module--box-ghost--3nGW4";
export var boxGhostBottom = "error-module--box-ghost-bottom--rwo1c";
export var boxGhostBottomChild = "error-module--box-ghost-bottom-child--rSrQ9";
export var boxGhostContainer = "error-module--box-ghost-container--Opw5R";
export var boxGhostEyes = "error-module--box-ghost-eyes--FkVv4";
export var boxGhostShadow = "error-module--box-ghost-shadow--JtTaE";
export var shine = "error-module--shine--NYyiE";
export var smallnbig = "error-module--smallnbig--I5p4I";
export var symbol = "error-module--symbol--QduIz";
export var upndown = "error-module--upndown--nwcYS";